import React, { useEffect } from "react";
import { Link, Router } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../assets/WYT LOGO.png"
import { useState } from "react";

const Navbar = () => {
  const handleNavCollapse = () =>{
    const set = document.body;
    set.addEventListener("click",function(){
      // console.log("object");
      // console.log(set);
      document.getElementById('clickButton').click();
    })
  };
  handleNavCollapse();
  var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-50px";
  }
  prevScrollpos = currentScrollPos;
}

  return (
    <div className="navigation">
      <nav class="navbar navbar-expand-lg" id="navbar_id">
      <div class="container-fluid container navbar-full">
        <a class="navbar-brand" href="#">
         <img className="img-fluid logo" src={Logo} alt="" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="clickButton"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#about">
                About
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#objective">
                Our Objective
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#expert">
                Expert
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#services">
                Services
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#blog">
                Blog
              </a>
            </li>
          </ul>
          <div>
          <a class="nav-link contact-btn" aria-current="page" href="#contact">
              Contact Us
              </a>
          </div>
        </div>
      </div>
    </nav>
    </div>
  );
};

export default Navbar;
